/* src/components/Footer.css */

.footer {
  background-color: #333;
  color: #fff;
  text-align: center;
}

.footer-content {
  max-width: 960px;
  margin: 0 auto;
  padding: 10px 10px;
}

.footer a {
  color: #fff;
  text-decoration: underline;
}

.footer a:hover {
  text-decoration: none;
}
@media (max-width:576px){
  .footer-content p{
    font-size: 1em;
  }
}