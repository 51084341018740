.brand {
    display: flex;
    align-items: center;
  }
  
  .logo {
    height: 40px; /* Adjust as needed */
    margin-right: 10px;
  }
  
  .brand-name {
    font-size: 1.5rem;
    font-weight: bold;
  }
  