/* style.css */

.contact-container {
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.contact-container p {
  text-align: justify;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

/* label {
  font-weight: bold;
} */

input,
textarea {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 95%; /* Adjusted width */
}

textarea {
  height: 120px;
}

/* Align label to top left of textarea */
/* textarea + label {
  display: block;
  margin-bottom: 5px;
} */

button {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 15px; /* Decreased padding */
  font-size: 16px;
  cursor: pointer;
  align-self: flex-start; /* Align button to the start of the flex container */
}

button:hover {
  background-color: #45a049;
}

.status-message {
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
  color: #4caf50;
}
