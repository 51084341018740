/* src/components/Header/style.css */

/* General Navbar Styles */
.navbar {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  padding-right: 15px;
}

.nav-items {
  display: flex;
  justify-content: center;
}

.nav-item {
  margin: 0 10px;
  padding: 10px;
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
}

.nav-item:hover {
  border-bottom: 1px solid #fff;
}

.nav-item.active,
.nav-item:focus {
  border: none;
  font-size: 1.2em;
  font-weight: bold;
}

/* Hamburger Menu Styles */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
  z-index: 1100;
  /* Ensure it appears above the menu */
}

.hamburger .bar {
  height: 4px;
  width: 25px;
  background-color: #fff;
  margin: 3px 0;
  transition: 0.4s;
}

.hamburger .bar.open:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger .bar.open:nth-child(2) {
  opacity: 0;
}

.hamburger .bar.open:nth-child(3) {
  transform: rotate(-45deg) translate(9px, -9px);
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .nav-items {
    display: none;
    flex-direction: column;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50px;
    /* Adjust based on your navbar height */
    left: 0;
    background-color: blue;
    /* Ensure it matches your design */
    z-index: 1000;
  }

  .nav-items.open {
    display: flex;
  }

  .nav-item {
    margin: 10px 0;
  }

  .hamburger {
    display: flex;
  }
}