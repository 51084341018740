/* General styles */
.skills-container {
  width: 80%;
  margin: 0 auto;
}

.skills-title {
  letter-spacing: 0.1em;
  text-align: center;
}

/* Customize the tab styles */
.react-tabs__tab .react-tabs__tab--selected {
  border-bottom: 1px solid #ccc;
  margin: 0 0 10px;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 10px 15px;
  cursor: pointer;
  margin-bottom: 10px;
  z-index: 2; /* Set higher z-index for unselected tabs */
}

.react-tabs__tab--selected{
  border-color: #aaa;
  border-radius: 5px 5px 0 0;
  border-bottom: 1px soolid #DDd;
  background-color: #4caf50  !important;
  color: #fff  !important;
  z-index: 1 !important; /* Set lower z-index for selected tab */
}

.react-tabs__tab--selected:after {
  content: '';
  display: block;
  height: 2px;
  background: #4caf50;
  position: absolute;
  bottom: 0;
  left: 0;
}

.tab-content {
  margin-top: 20px;
}

.tab-title {
  font-size: 1.5em;
  margin-bottom: 20px;
  text-align: center;
}

.progress-lines {
  position: relative;
  height: 200px;
  margin-bottom: -40px;
}

.line {
  width: 2px;
  background-color: #ddd;
  position: absolute;
}

.line[data-label="Basic"] {
  left: 0;
}

.line[data-label="Good"] {
  left: 50%;
  transform: translateX(-50%);
}

.line[data-label="Expert"] {
  right: 0;
}

.line:before {
  content: attr(data-label);
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
}

.skill {
  margin-bottom: 20px;
}

.skill-title {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.progress-bar {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
}

.progress {
  height: 20px;
  background-color: #4caf50;
  text-align: right;
  padding-right: 5px;
  line-height: 20px;
  color: white;
  border-radius: 5px;
}

.progress-label {
  padding-right: 10px;
  font-size: 12px;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .skills-container {
    width: 90%;
  }

  .progress-lines {
    height: 150px;
  }

  .line:before {
    font-size: 10px;
  }
}

@media (max-width: 992px) {
  .skills-container {
    width: 90%;
  }

  .react-tabs__tab {
    padding: 8px 10px;
  }

  .progress-lines {
    height: 120px;
  }

  .skill-title {
    font-size: 14px;
  }

  .progress {
    height: 18px;
  }

  .progress-label {
    font-size: 10px;
  }
}

@media (max-width: 768px) {
  .react-tabs__tab-list {
    flex-direction: column;
    align-items: center;
  }

  .react-tabs__tab {
    width: 100%;
    text-align: center;
  }

  .progress-lines {
    height: 100px;
  }

  .line {
    margin-right: 5px;
  }

  .line:before {
    top: -20px;
    font-size: 9px;
  }

  .skill-title {
    font-size: 12px;
  }

  .progress {
    height: 16px;
  }

  .progress-label {
    font-size: 9px;
  }
}

@media (max-width: 576px) {
  .skills-title h1 {
    font-size: 24px;
  }

  .react-tabs__tab {
    padding: 6px 8px;
  }

  .progress-lines {
    height: 80px;
  }

  .line {
    margin-right: 3px;
  }

  .line:before {
    top: -15px;
    font-size: 8px;
  }

  .skill-title {
    font-size: 10px;
  }

  .progress {
    height: 14px;
  }

  .progress-label {
    font-size: 8px;
  }
}