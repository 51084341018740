@font-face {
    font-family: 'Roboto';
    src: url('../../../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    /* Add additional src lines for different font file formats if available */
  }
.about {
    background-color: #cad6d4;
    font-family: 'Roboto', sans-serif;
}
.about .about-title{
    margin-top: 0;
    padding-top: 20px;
    letter-spacing: 0.1em;
}
.about-container {
    display: flex;
}
.about-img {
    flex: 35% 1;
   padding-top: 15px;
}
.about-img img {
    width: 100%;
}
.about-intro{
    flex: 65% 1;
}
.about-intro p{
    text-align: justify;
    padding-left: 10px;
    padding-right: 10px;
    letter-spacing: 1px;
    font-size: 16px;
    line-height: 1.5em;
}

@media (max-width: 780px) {
    .about-container {
        display: flex;
        flex-direction: column;
    }
    .about-intro p{
        font-size: 14px;
    }
}
@media (max-width: 480px) {
    .about-intro p{
        text-align: justify;
        font-size: 12px;
    }
}

  