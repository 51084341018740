.header {
  background-image: linear-gradient(to bottom, rgba(0, 213, 255, 0.2), rgba(32, 14, 198, 0.4)), url('../../../public/assets/images/header-background.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
}

.navbar-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  background-color: blue;
  box-shadow: 0 4px 8px rgb(38 77 149 / 90%);
  padding: 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.brand {
  order: 1;
}

.navbar {
  order: 2;
}

.header-titles {
  text-align: center;
  margin-top: 80px; /* Ensure the titles are not covered by the fixed navbar */
}

.headerMainTitle {
  font-size: 2em;
  margin-bottom: 10px;
}

.headerSubTitle {
  font-size: 1.2em;
  margin-bottom: 5px;
}

.header-btn {
  box-shadow: 0 4px 8px rgb(23 55 209 / 61%);
  margin-top: 20px;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .header {
    height: auto;
    padding: 15px;
    background-size: contain; /* Adjusts the background size */
  }

  .headerMainTitle {
    font-size: 1.8em;
  }

  .headerSubTitle {
    font-size: 1em;
  }

  .header-btn {
    font-size: 1em;
    padding: 10px 20px;
  }
}

@media (max-width: 768px) {
  .navbar-wrapper {
    flex-direction: column;
    padding: 10px;
  }

  .navbar {
    margin-top: 10px;
  }

  .header {
    padding: 10px;
    height: auto;
    background-size: cover; /* Ensures the background covers the entire area */
  }

  .header-titles {
    margin-top: 120px; /* Ensure the titles are not covered by the fixed navbar */
  }

  .headerMainTitle {
    font-size: 1.6em;
  }

  .headerSubTitle {
    font-size: 0.9em;
  }

  .header-btn {
    font-size: 0.9em;
    width: 100%;
    padding: 10px 15px;
  }
}

@media (max-width: 480px) {
  .header {
    padding: 10px;
    height: auto;
    background-size: cover; /* Ensures the background covers the entire area */
  }

  .header-titles {
    margin-top: 140px; /* Ensure the titles are not covered by the fixed navbar */
  }

  .headerMainTitle {
    font-size: 1.4em;
  }

  .headerSubTitle {
    font-size: 0.8em;
  }

  .header-btn {
    font-size: 0.8em;
    width: 100%;
    padding: 8px 12px;
  }
}

/* Styles for the Brand component */
.brand {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px; /* Adjust as needed */
  margin-right: 10px;
}

.brand-name {
  font-size: 1.5rem;
  font-weight: bold;
}

@media (max-width: 768px) {
  .brand-name {
    font-size: 1.2rem;
  }

  .logo {
    height: 35px;
  }
}

@media (max-width: 480px) {
  .brand-name {
    font-size: 1rem;
  }

  .logo {
    height: 30px;
  }
}
