.services-container {
  text-align: center;
  padding: 20px;
}

.services-title {
  letter-spacing: 0.1em;
}

.services-title h1 {
  margin-bottom: 20px;
  font-size: 2em;
  color: #333;
}

.services-boxes {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.service-box {
  flex: 1 1 calc(33.333% - 40px);
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  background-color: #f9f9f9;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin: 10px;
  max-width: calc(33.333% - 40px);
}

.service-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.service-image {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #ddd;
}

.service-title {
  font-size: 1.5em;
  margin: 15px 0;
  color: #333;
}

.service-description {
  font-size: 1em;
  color: #666;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .service-box {
    flex: 1 1 calc(50% - 40px);
    max-width: calc(50% - 40px);
  }

  .services-title h1 {
    font-size: 1.8em;
  }

  .service-title {
    font-size: 1.3em;
  }

  .service-description {
    font-size: 0.9em;
  }

  .service-image {
    height: auto;
  }
}

@media (max-width: 768px) {
  .service-box {
    flex: 1 1 calc(100% - 40px);
    max-width: calc(100% - 40px);
  }

  .services-title h1 {
    font-size: 1.6em;
  }

  .service-title {
    font-size: 1.2em;
  }

  .service-description {
    font-size: 0.85em;
  }

  .service-image {
    height: auto;
  }
}

@media (max-width: 480px) {
  .service-box {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .services-title h1 {
    font-size: 1.4em;
  }

  .service-title {
    font-size: 1.1em;
  }

  .service-description {
    font-size: 0.8em;
  }

  .service-image {
    height: auto;
  }
}
